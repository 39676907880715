import React from 'react';

const NotFound: React.FC = () => {
  return (
    <div className="page-container">
      <div className="notfound-page text-center">
        <p className="text-4xl font-bold text-gray-900">404</p>
        <h1 className="mt-4 font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out"
            style={{
              backgroundColor: 'var(--secondary-color)',
              'boxShadow': 'var(--primary-color)',
            }}
            onMouseEnter={(e) => {
              (e.currentTarget as HTMLElement).style.backgroundColor = 'var(--primary-color)';
              (e.currentTarget as HTMLElement).style.color = 'var(--secondary-color)';
            }}
            onMouseLeave={(e) => {
              (e.currentTarget as HTMLElement).style.backgroundColor = 'var(--secondary-color)';
              (e.currentTarget as HTMLElement).style.color = 'white';
            }}
            >
            Go back home
          </a>
        </div>
      </div>
  </div>
  );
};

export default NotFound;
