import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import settings from '../website_settings.json';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        closeMenu();
      }
    };
    
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <nav className={`navbar flex items-center justify-between p-4 shadow-md fixed top-0 left-0 w-full z-50 ${isLandingPage ? 'landing-navbar' : ''}`}>
      <Link to="/" className="flex items-center">
        <img
          src={process.env.PUBLIC_URL + '/' + settings.logoPath}
          alt="Logo"
          className="navbar-logo rounded-full mr-4"
        />
      </Link>
      <div className="hidden lg:flex space-x-4">
        <Link to="/" className="navbar-link">Home</Link>
        {settings.pages.Menu && <Link to="/menu" className="navbar-link">Menu</Link>}
        {settings.pages.Contact && <Link to="/contact" className="navbar-link">Contact</Link>}
        {settings.pages.Shop && <Link to="/shop" className="navbar-link">Shop</Link>}
      </div>
      <button ref={buttonRef} onClick={toggleMenu} className="block lg:hidden">
        {isOpen ? (
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg className="hamburger-icon h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        )}
      </button>
      {isOpen && (
        <div ref={menuRef} className="hamburger-menu absolute top-16 right-0 shadow-lg w-full lg:hidden flex flex-col items-center justify-center space-y-4 py-4">
          <Link to="/" className="block p-4" onClick={closeMenu}>Home</Link>
          {settings.pages.Menu && <Link to="/menu" className="block p-4" onClick={closeMenu}>Menu</Link>}
          {settings.pages.Contact && <Link to="/contact" className="block p-4" onClick={closeMenu}>Contact</Link>}
          {settings.pages.Shop && <Link to="/shop" className="block p-4" onClick={closeMenu}>Shop</Link>}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
