import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import settings from '../website_settings.json';

SwiperCore.use([Navigation, Pagination]);

const InstagramGallery: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInstagramImages = async () => {
      try {
        const response = await fetch(
          `https://graph.instagram.com/me/media?fields=id,caption,media_url&access_token=${settings.instagramAccessToken}`
        );
        const data = await response.json();
        const imageUrls = data.data.slice(0, 10).map((item: any) => item.media_url);
        setImages(imageUrls);
      } catch (error) {
        console.error('Error fetching Instagram images:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInstagramImages();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (images.length === 0) {
    return null; // Hide the component if there are no images
  }

  return (
    <div className="instagram-carousel">
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        loop
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Instagram ${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <a
        href={`https://www.instagram.com/${settings.instagramUsername}`}
        target="_blank"
        rel="noopener noreferrer"
        className="follow-us-button"
      >
        Follow us!
      </a>
    </div>
  );
};

export default InstagramGallery;
