import React, { useEffect, useState } from 'react';
import settings from '../website_settings.json';

const HeroSection: React.FC = () => {
    const [visible, setVisible] = useState(false);

    const handleScroll = () => {
        const aboutSection = document.querySelector('#about-section') as HTMLElement;
        if (aboutSection) {
            window.scrollTo({
                top: aboutSection.offsetTop,
                behavior: 'smooth',
            });
        }
        setVisible(true); // Optionally make arrow visible when scrolling
    };

    useEffect(() => {
        // Show arrow after 1 second
        const timeout = setTimeout(() => {
            setVisible(true);
        }, 1000);

        const handleScrollEvent = () => {
            setVisible(true); // Show arrow when user scrolls
        };

        window.addEventListener('scroll', handleScrollEvent);

        return () => {
            clearTimeout(timeout);
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);

    return (
        <div className="hero-section relative flex flex-col items-center justify-center h-screen bg-white">
            {/* Logo Section */}
            <div className="logo-section flex items-center justify-center mb-36">
                <a href="/">
                    <img src={process.env.PUBLIC_URL + '/' + settings.logoPath} alt="Logo" className="hero-logo" />
                </a>
            </div>

            {/* Nav Section */}
            <div className="nav-section flex flex-col items-center space-y-4">
                {settings.pages.Menu && <a href="menu" className="hero-link">Menu</a>}
                {settings.pages.Contact && <a href="contact" className="hero-link">Contact</a>}
                {settings.pages.Shop && <a href="shop" className="hero-link">Shop</a>}
            </div>

            {/* Arrow Animation Section */}
            <div className="arrow-section absolute bottom-8 left-1/2 transform -translate-x-1/2">
                <button onClick={handleScroll}>
                    <svg
                        className={`arrow h-12 w-12 animate-bounce transition-opacity duration-700 ${
                            visible ? 'opacity-100' : 'opacity-0'
                        } hover:opacity-100`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default HeroSection;