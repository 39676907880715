import React, { useEffect, useState } from 'react';
import settings from '../website_settings.json';
import HeroSection from './HeroSection';
import ReactPlayer from 'react-player';
import InstagramGallery from './InstagramGallery';

const LandingPage: React.FC = () => {
  const [calcWidth, setCalcWidth] = useState<number>(0);
  const [calcHeight, setCalcHeight] = useState<number>(0);
  const [videoZoom, setVideoZoom] = useState<number>(1);
  const [blurIntensity, setBlurIntensity] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      const newWindowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      const windowAspectRatio = newWindowDimensions.width / newWindowDimensions.height;
      const videoAspectRatio = 16 / 9;

      let newVideoZoom = 1;

      if (windowAspectRatio > videoAspectRatio) {
        newVideoZoom = newWindowDimensions.width / (newWindowDimensions.height * videoAspectRatio);
      } else {
        newVideoZoom = (newWindowDimensions.height * videoAspectRatio) / newWindowDimensions.width;
      }

      setVideoZoom(newVideoZoom);
      setCalcWidth(newWindowDimensions.width);
      setCalcHeight(newWindowDimensions.height);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const navbar = document.querySelector('.navbar') as HTMLElement;
      const heroSection = document.querySelector('.hero-section') as HTMLElement;
      const aboutSection = document.querySelector('#about-section') as HTMLElement;

      // Hide the navbar by default on page load
      navbar.style.transform = 'translateY(-100%)';

      // Logic for hiding and showing navbar
      if (scrollY > heroSection.clientHeight) {
        navbar.style.transform = 'translateY(0)';
      } else {
        navbar.style.transform = 'translateY(-100%)';
      }

      const aboutSectionRect = aboutSection.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const distanceFromCenter = Math.abs(aboutSectionRect.top + aboutSectionRect.height / 2 - viewportHeight / 2);
      const blurAmount = Math.min(5, distanceFromCenter / viewportHeight * 20);
      setBlurIntensity(blurAmount);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative">
      <HeroSection />
      <div id="about-section" className="video-container mb-20" style={{ height: `${calcHeight}px` }}>
        {calcWidth && calcHeight && (
          <ReactPlayer 
            url={settings.backgroundVideoUrl}
            controls={false}
            playing={true}
            loop={true}
            muted={true}
            height={`${calcHeight}px`}
            width={calcWidth}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: `translate(-50%, -50%) scale(${videoZoom})`,
              objectFit: 'cover',
              filter: `blur(${blurIntensity}px)`,
            }}
            config={{
              youtube: {
                playerVars: {
                  controls: 0,
                },
              },
            }}
          />
        )}
        <div className="overlay absolute inset-0 flex items-center justify-center">
          <div className="text-box bg-white bg-opacity-75 rounded shadow-lg">
            <p className="about-text">{settings.aboutText}</p>
          </div>
        </div>
      </div>

      <div className="container instagram-gallery mb-20">
        <InstagramGallery />
      </div>

      <div id="contact-section" className="container address-contact-location content-center grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="openingtimes-contact">
          <div className="opening-times p-4">
            <h2 className="text-xl font-bold mb-2">Opening Times</h2>
            <div className="opening-times-grid">
              {Object.entries(settings.openingTimes).map(([day, time]) => (
                <div key={day} className="opening-time-row">
                  <span className="day">{day}</span>
                  <span className="time">{time}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="contact p-4">
            <h2 className="text-xl font-bold mb-2">Contact Info</h2>
            <p>{settings.address.street}</p>
            <p>{settings.address.postalCode}, {settings.address.City} </p>
            <p>{settings.address.Country}</p>
            <p><a className="email-ref" href={`mailto:${settings.email}`}>{settings.email}</a></p>
          </div>
        </div>
∫
        <div className="map-location p-4">
          <iframe
            title="Google maps iframe"
            src={`${settings.googleMapsLink}`}
            width="100%"
            height="200"
            allowFullScreen
            loading="lazy"
            className="rounded"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
