import React, { FC } from 'react';

interface ModalProps {
  show: boolean;
  onClose: () => void;
}

const Modal: FC<ModalProps> = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <iframe
          src="./assets/privacy_policy/privacyPolicy.html"
          title="Privacy Policy"
          className="modal-iframe"
        ></iframe>
      </div>
    </div>
  );
};

export default Modal;
