import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import websiteSettings from "../website_settings.json";

interface StickerProps {
  targetClass: string; // Class of the target component
}
// Default text used for the sticker
const defaultText = 'We are hiring!'

const Sticker: React.FC<StickerProps> = ({ targetClass }) => {

  const [text, setText] = useState<string>("We are hiring!"); // Default text
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null); // Store the target element

  useEffect(() => {
    // Check if custom text is provided in settings
    if (websiteSettings.customStickerText) {
      setText(websiteSettings.customStickerText);
    } else
    {setText(defaultText)}
  }, []);

  useEffect(() => {
    // Select the target element and set it
    const element = document.querySelector<HTMLElement>(`.${targetClass}`);
    if (element) {
      element.style.position = "relative"; // Ensure the target has relative position
    }
    setTargetElement(element); // Store the target element
  }, [targetClass]);

  // If the target element is not found, return null
  if (!targetElement) return null;
  // If the sticker is not in settings or set to false it will not be displayed
  if (!websiteSettings.sticker) return null;

  // Use React Portal to render the sticker inside the target element
  return ReactDOM.createPortal(
    <div className="sticker">
      {text}
    </div>,
    targetElement
  );
};

export default Sticker;