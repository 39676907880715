import React from 'react';
import ContactForm from '../components/ContactForm';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import settings from '../website_settings.json';
import Sticker from '../components/Sticker';

// TODO make page sections dynamic
// [x] add a we're hiring element for page
// [ ] update json type website settings
// [ ] make all setting elements optionally rendered


const Contact: React.FC = () => {
  return (
    <div className="page-container">
    <div className="contact-page">
      <div className="contact-details">
        <h2>Contact Us</h2>
        <p>Do you want to make a large reservation and want to request a quote? You want to come work for us? Or you have another question? Feel free to reach out to us!</p>
        <div className="contact-items">
        <div className="contact-item">
          <LocationOnIcon className="contact-icon" />
          <p>{settings.address.street} <br/> {settings.address.postalCode} {settings.address.City}<br/>{settings.address.Country}</p>
        </div>
        <div className="contact-item">
          <PhoneIcon className="contact-icon" />
          <p><a href={`tel:${settings.phoneNumber}`} className="phone-number">{settings.phoneNumber}</a></p>
        </div>
        <div className="contact-item">
          <EmailIcon className="contact-icon" />
          <p><a href={`mailto:${settings.email}`} className="email">{settings.email}</a></p>
        </div>
        </div>
      </div>
      <div className="contact-form-container">
        <ContactForm />
      </div>
      <Sticker targetClass='contact-details'/>
    </div>
    </div>
  );
};

export default Contact;
