import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Menu from './pages/Menu';
import ShopPage from './pages/Shop';
import settings from './website_settings.json';
import NotFound from "./pages/NotFound";

async function loadCustomFonts(fonts: Array<any>): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      for (let index = 0; index < fonts.length; index++) {
        let fontName = Object.keys(fonts[index])

        if (fontName.length !== 1){
          throw new Error('Given custom font has multiple names, please only assign one key name per object!')
        }
        else{
          const fontStyles = fonts[index][fontName[0]]
          const styleSheet = document.createElement('style');
          styleSheet.type = 'text/css';
    
          let fontFaces = '';
    
          fontStyles.forEach((font: any) => {
            let sources = '';
    
            font.formats.forEach((formatEntry: { formatFilePath: string; }) => {
              const formatType = formatEntry.formatFilePath.split('.').pop();
              const url = formatEntry.formatFilePath;
    
              sources += `url('${url}') format('${formatType}'), `;
            });
    
            sources = sources.slice(0, -2); // Remove trailing comma
    
            fontFaces += `
              @font-face {
                font-family: '${fontName}';
                src: ${sources};
                ${font.weight ? `font-weight: ${font.weight};` : 'font-weight: 200;'}
                ${font.style ? `font-style: ${font.style};` : 'font-style: normal;'}
                ${font['letter-spacing'] ? `letter-spacing: ${font['letter-spacing']};` : ''}
              }
            `;
          });
    
          styleSheet.appendChild(document.createTextNode(fontFaces));
          document.head.appendChild(styleSheet);
    
          resolve();
          
        }
        
      }
    } catch (error) {
      reject(error);
    }
  });
}

// Function to set CSS variables
async function setCSSVariables(settings: any) {
  const root = document.documentElement;
  root.style.setProperty('--primary-color', settings.primaryColor);
  root.style.setProperty('--secondary-color', settings.secondaryColor);
  root.style.setProperty('--font-family', `'${settings.fontFamily}', sans-serif`);
  root.style.setProperty('--background-image', `url(${process.env.PUBLIC_URL}/${settings.backgroundImagePath})`);

  // Load fonts dynamically from the JSON
  if (Object.keys(settings.customFonts).length > 0){
    await loadCustomFonts(settings.customFonts)
  }
}

// Set the CSS variables and load fonts when the document is loaded
document.addEventListener('DOMContentLoaded', async () => {
  await setCSSVariables(settings);
});

const App: React.FC = () => {
  useEffect(() => {
    async function loadSettingsAndFonts() {
      await setCSSVariables(settings); // Ensure CSS variables and fonts are set
    }
    
    // Set the document title from settings
    if (settings.websiteTitle) {
      document.title = settings.websiteTitle;
    }
    
    loadSettingsAndFonts();

  }, []); // Empty dependency array ensures it runs only once

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {settings.pages.Contact ? <Route path="/contact" element={<Contact />} /> : <Route path="/notFound" element={<NotFound />} /> }
        {settings.pages.Menu ? <Route path="/menu" element={<Menu />} /> : <Route path="/notFound" element={<NotFound />} /> }
        {settings.pages.Shop ? <Route path="/shop" element={<ShopPage />} />: <Route path="/notFound" element={<NotFound />} /> }
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;