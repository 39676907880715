import React, { useState, useEffect, useRef } from 'react';
import settings from '../website_settings.json';
import { Switch } from '@headlessui/react';
import SendIcon from '@mui/icons-material/Send';
import Modal from './Modal';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
  files: FileList | null;
}

const allowedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
const maxFileSize = 5 * 1024 * 1024; // 5MB

const sanitizeInput = (value: string) => {
  return value.replace(/[^a-zA-Z0-9\s@$£€!.,?()&\-+]/g, '');
};

// Phone number validation for international and local (06) formats
const isPhoneNumberValid = (phoneNumber: string) => {
  const phoneRegex = /^(?:\+?[0-9]{1,3})?[ ]?(?:06|[1-9][0-9])?[ ]?[0-9]{7,9}$/;
  return phoneRegex.test(phoneNumber);
};

const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isFilesValid = (files: FileList | null) => {
  if (!files) return true; // If no files, it's valid
  return Array.from(files).every(
    (file) => allowedFileTypes.includes(file.type) && file.size <= maxFileSize
  );
};

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
    files: null,
  });

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    message: false,
    files: false,
  });

  const [agreed, setAgreed] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Add ref for the file input element
  
  useEffect(() => {
    // Check if all fields are filled, valid, and agreement is checked
    const isValid = !!(
      formData.firstName.trim() &&
      formData.lastName.trim() &&
      isEmailValid(formData.email) &&
      isPhoneNumberValid(formData.phoneNumber) &&
      formData.message.trim() &&
      isFilesValid(formData.files) &&  // Ensure this works with FileList
      agreed
    );
    setFormValid(isValid);
  }, [formData, agreed]);

  const fileArrayToFileList = (fileArray: File[]): FileList => {
    const dataTransfer = new DataTransfer();
  
    fileArray.forEach((file) => {
      dataTransfer.items.add(file); // Add each file to DataTransfer
    });
  
    return dataTransfer.files; // Return a FileList
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === "files" && e.target instanceof HTMLInputElement) {
      const files = e.target.files;
  
      if (files) {
        const filesArray = Array.from(files); // Convert FileList to File[]
        const isValidFiles = isFilesValid(fileArrayToFileList(filesArray));
    
        setFormData((prevState) => ({
          ...prevState,
          files: fileArrayToFileList(filesArray), // Convert File[] back to FileList
        }));
    
        setFormErrors({ ...formErrors, files: !isValidFiles });
      }
    } else {
      const sanitizedValue = sanitizeInput(value);
      setFormData({
        ...formData,
        [name]: sanitizedValue,
      });

      // Check for empty fields and validation errors
      if (name === 'email') {
        setFormErrors({ ...formErrors, email: !isEmailValid(sanitizedValue) });
      } else if (name === 'phoneNumber') {
        setFormErrors({ ...formErrors, phoneNumber: !isPhoneNumberValid(sanitizedValue) });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: sanitizedValue === '',
        });
      }
    }
  };

  const handleRemoveFile = (fileToRemove: File) => {
    setFormData((prevState) => {
      if (!prevState.files) return prevState;
  
      const updatedFiles = Array.from(prevState.files).filter(
        (file) => file !== fileToRemove
      );
  
      // Clear the file input if no files are left
      if (updatedFiles.length === 0 && fileInputRef.current) {
        fileInputRef.current.value = ''; // Clear the input value
      }
  
      return {
        ...prevState,
        files: updatedFiles.length ? fileArrayToFileList(updatedFiles) : null,
      };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formValid) return;

    let mailtoLink = `mailto:${settings.email}?subject=Contact Form Submission&body=Name: ${formData.firstName} ${formData.lastName}%0D%0AEmail: ${formData.email}%0D%0APhone: ${formData.phoneNumber}%0D%0AMessage: ${formData.message}`;
    if (formData.files) {
      const filesArray = Array.from(formData.files);
      filesArray.forEach((file) => {
        mailtoLink += `%0D%0AAttachment: ${file.name}`;
      });
    }
    window.location.href = mailtoLink;
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="form-grid">
        <div className="form-group">
          <label htmlFor="first-name">First name</label>
          <input
            type="text"
            name="firstName"
            id="first-name"
            value={formData.firstName}
            onChange={handleChange}
          />
          {formErrors.firstName && <span className="error-message">First name is required</span>}
        </div>
        <div className="form-group">
          <label htmlFor="last-name">Last name</label>
          <input
            type="text"
            name="lastName"
            id="last-name"
            value={formData.lastName}
            onChange={handleChange}
          />
          {formErrors.lastName && <span className="error-message">Last name is required</span>}
        </div>
        <div className="form-group full-width">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
          />
          {formErrors.email && <span className="error-message">Invalid email format</span>}
        </div>
        <div className="form-group full-width">
          <label htmlFor="phone-number">Phone number</label>
          <input
            type="tel"
            name="phoneNumber"
            id="phone-number"
            value={formData.phoneNumber}
            autoComplete="tel"
            onChange={handleChange}
            pattern="^\+?[0-9]*$"
          />
          {formErrors.phoneNumber && <span className="error-message">Invalid phone number</span>}
        </div>
        <div className="form-group full-width">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            rows={4}
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <div className="form-group full-width">
  <label htmlFor="files">Attachments</label>
  <input
  type="file"
  name="files"
  id="files"
  onChange={handleChange}
  multiple
  ref={fileInputRef} // Attach ref to the input
  />
  {formErrors.files && (
    <span className="error-message">Invalid file type or size</span>
  )}

  {/* Display uploaded files with an "X" to remove */}
  {formData.files && formData.files.length > 0 && (
    <div className="selected-files">
      {Array.from(formData.files).map((file, index) => (
        <div key={index} className="file-item">
          <span>{file.name}</span>
          <button
            type="button"
            onClick={() => handleRemoveFile(file)}
            className="remove-file"
          >
            X
          </button>
        </div>
      ))}
    </div>
  )}
</div>
      </div>

      <div className="form-agreement">
        <Switch
          checked={agreed}
          onChange={setAgreed}
          className={`${agreed ? 'bg-green-500' : 'bg-gray-200'} relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-200 ease-in-out`}
        >
          <span
            className={`${agreed ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <label htmlFor="agreement" className="ml-3 text-sm text-gray-900">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          By selecting this, you agree to our <a className="privacy-policy" onClick={openModal}>privacy&nbsp;policy</a>.
        </label>
      </div>
      <button
        type="submit"
        className={`submit-button ${formValid ? '' : 'disabled-button'}`}
        disabled={!formValid}
      >
        <span className="send-it-text">Send it!&nbsp;</span> <SendIcon />
      </button>
      <Modal show={showModal} onClose={closeModal} />
    </form>
  );
};

export default ContactForm;