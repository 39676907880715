import React, { useEffect, useState } from 'react';
import Client from 'shopify-buy';

interface Product {
  id: string;
  title: string;
  description: string;
  images: { src: string }[];
  variants: { id: string; price: string }[];
}

// TODO finish Shop page
const ShopPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const client = Client.buildClient({ apiVersion: "",
    domain: 'your-shopify-store.myshopify.com', // Replace with your Shopify store domain
    storefrontAccessToken: 'your-storefront-access-token' // Replace with your Shopify storefront access token
  });

  useEffect(() => {
    client.product.fetchAll().then((products: any) => {
      setProducts(products);
    });
  }, [client]);

  return (
    <div className="page-container">
    <div className="container mx-auto py-16">
      <h1 className="text-3xl font-bold mb-8">Shop</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {products.map((product) => (
          <div key={product.id} className="border p-4">
            <img src={product.images[0].src} alt={product.title} className="w-full h-48 object-cover mb-4" />
            <h2 className="text-xl font-bold">{product.title}</h2>
            <p className="text-gray-700">{product.description}</p>
            <p className="text-lg font-bold mt-4">${product.variants[0].price}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default ShopPage;
